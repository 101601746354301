import Vue from 'vue'
import axios from 'axios'
//import api from "@/api/index"

const userGuide = {
    namespaced: true,
    state: {
        tabData: [
            {
                id: 0,
                name: "이용안내",
                route: "/userGuide/main"
            }, {
                id: 1,
                name: "환불규정",
                route: "/userGuide/refundPolicy",
            },/* {
                id: 2,
                name: "비상시 대책요령",
                route: "/userGuide/useInfo/2",
            }*/
        ],


    },
    mutations: {
        SAVE: (state, info) => {
            try {
                // 1. default setting

                if (true) {
                    //data.list = set_default(data.message);

                    // 2. 저장소 적용
                    Vue.set(state, 'userGuideData', info);
                } else {
                    //alert(data.result)
                    Vue.set(state, 'userGuideData', {});
                }

            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , LOAD: (state, info) => {

        }
        , CLEAR({state}) {
        }
    },

    actions: {}
    , getters: {
        // isLogin : state => state._id == "" ? false : true
    }
}

export default userGuide
