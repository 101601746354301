import Vue from 'vue'
import axios from 'axios'
import api from "@/api/index"
//import api from "@/api/index"
import cloneDeep from 'lodash/cloneDeep'

const getDefaultState = () => {
    return {
        resvSearchData: [], // 검색한 방목록
        resvSelectData: {   // 선택한 방, 캠핑장 정보
            roomNm: "캠핑장-A",
            stdNop: "4",
            maxNop: "5",
            osrWe: "",
            osrWd: "",
            spsrWe: "",
            spsrWd: "",
            psrWe: "",
            psrWd: "",
        },
        resvSelectList: [],     // 선택 가능한 방,캠핑장 목록
        resvConfirmList: [],    // 최종 확인 리스트
        resvConfirmInfo: {      // 최종 예약 정보
            resvId: undefined,
            resvPw: "",
            resvName: "",
            resvPhone: "",
            resvGubun: "",
            resvDate: "",
            resvPrice: "",
            payName: "",
            payDate: "",
            resvMemo: "",
            adminMemo: "",
            roomData: [],

        },
        resvUsrInfo: {},        // 사용자 입력 정보
        fullPrice: 0,   // 합계 금액
    }
};

const reservation = {
    namespaced: true,
    state: {
        resvSearchData: [], // 검색한 방목록
        resvSelectData: {   // 선택한 방, 캠핑장 정보
            roomNm: "캠핑장-A",
            stdNop: "4",
            maxNop: "5",
            osrWe: "",
            osrWd: "",
            spsrWe: "",
            spsrWd: "",
            psrWe: "",
            psrWd: "",
        },
        resvSelectList: [],     // 선택 가능한 방,캠핑장 목록
        resvConfirmList: [],    // 최종 확인 리스트
        resvConfirmInfo: {      // 최종 예약 정보
            resvId: undefined,
            resvPw: "",
            resvName: "",
            resvPhone: "",
            resvGubun: "",
            resvDate: "",
            resvPrice: "",
            payName: "",
            payDate: "",
            resvMemo: "",
            adminMemo: "",
            roomData: [],

        },
        resvUsrInfo: {},        // 사용자 입력 정보
        fullPrice: 0,   // 합계 금액
    },
    mutations: {
        LOAD: (state, info) => {

        }
        , CLEAR({state}) {

            getDefaultState();

        }
        , SAVE_RESV_SELECT_DATA: (state, info) => {
            try {

                Vue.set(state, 'resvSelectData', []);
                Vue.set(state, 'resvSelectData', info.message[0]);

                let roomList = info.message[0].roomList;

                for (let i = 0; roomList.length > i; i++) {

                    roomList[i]["isCheck"] = false;
                    //roomList[i]["resvNop"] = 4;
                    roomList[i]["resvNop"] = parseInt(roomList[i]["stdNop"]);
                    roomList[i]["maxNopNum"] = parseInt(roomList[i]["maxNop"]);
                    roomList[i]["totalPrice"] = parseInt(roomList[i]["price"]);
                }

                Vue.set(state, 'resvSelectList', []);
                Vue.set(state, 'resvSelectList', roomList);

            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , SAVE_RESV_CONFIRM: (state, info) => {

            //let fullPrice = 0;

            /*for(let i =0; info.length > i; i++){
                fullPrice += info[i].totalPrice;
            }*/

            //state.resvConfirmInfo.resvPrice = fullPrice;
            //state.fullPrice = fullPrice;
            //Vue.set(state, 'resvConfirmList', []);
            Vue.set(state, 'resvConfirmList', info);

        }
        , CLEAR_RESV_CONFIRM: (state) => {

            Vue.set(state, 'resvConfirmList', []);

        }
        , SAVE_RESV_USR_INFO: (state, info) => {

            Vue.set(state, 'resvUsrInfo', info);

        }
        , CLEAR_RESV_USR_INFO: (state) => {

            Vue.set(state, 'resvUsrInfo', []);

        }

    },

    actions: {
        getRoomKate({commit, dispatch, getters}, fdata) {   // 방검색
            return new Promise((resolve, reject) => {
                api.resv.getRoomKate(fdata)
                    .then(function (resp) {

                        if (fdata.roomGubun !== undefined) {
                            commit("SAVE_RESV_SELECT_DATA", resp);
                        } else {
                            //commit("SAVE_RESV_DATA", resp);
                        }

                        resolve(resp);

                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        resvUpdate({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {
                api.resv.resvUpdate(fdata)
                    .then(function (resp) {
                        resolve(resp);

                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        resvDelete({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {

                api.resv.resvDelete(fdata)
                    .then(function (resp) {

                        resolve(resp);

                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getResvConfirm({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {

                api.resv.resvConfirmList(fdata)
                    .then(function (resp) {

                        resolve(resp);

                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getCampItem({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {

                api.resv.getCampItem(fdata)
                    .then(function (resp) {
                        resolve(resp);

                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        orderUpdate({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {
                api.resv.orderUpdate(fdata)
                    .then(function (resp) {

                        resolve(resp);

                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
    },
    getters: {}
}

export default reservation
