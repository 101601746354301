<template>
    <v-row class="thumb-example">
        <v-col cols="12" md="6" class="hidden-md-and-up">
            <div class="swiper gallery-depiction-sm">
                <v-card
                        class="mx-auto my-auto"
                >
                    <v-card-text>
                        <!--<div>객실소개</div>-->
                        <p class="text-h4 text--primary">
                            수영장
                        </p>
                        <p></p>
                        <div class="text--primary">
                            여러명이서 함께 놀수있는 수영장 입니다.
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                                text
                                color="deep-purple accent-4"
                                @click="moreInfo()"
                        >
                            더보기
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </div>
        </v-col>
        <v-col
                cols="12"
                md="4">
            <div class="swiper gallery-depiction hidden-sm-and-down">
                <v-card
                        class="mx-auto"
                        style="height: 90%;"
                >
                    <v-card-text>
                        <!--<div>객실소개</div>-->
                        <p class="text-h4 text--primary">
                            수영장
                        </p>
                        <p></p>
                        <div class="text--primary">
                            여러명이서 함께 놀수있는 수영장 입니다.
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                                text
                                color="deep-purple accent-4"
                                @click="moreInfo()"
                        >
                            더보기
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </div>
            <!-- swiper2 Thumbs -->
            <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
                <swiper-slide class="slide-1"></swiper-slide>
                <swiper-slide class="slide-2"></swiper-slide>
                <swiper-slide class="slide-3"></swiper-slide>
                <swiper-slide class="slide-4"></swiper-slide>
                <swiper-slide class="slide-5"></swiper-slide>
                <swiper-slide
            </swiper>
        </v-col>
        <v-col
                cols="12"
                md="8">
            <!-- swiper1 -->
            <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
                <swiper-slide class="slide-1"></swiper-slide>
                <swiper-slide class="slide-2"></swiper-slide>
                <swiper-slide class="slide-3"></swiper-slide>
                <swiper-slide class="slide-4"></swiper-slide>
                <swiper-slide class="slide-5"></swiper-slide>
                <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            </swiper>
        </v-col>
    </v-row>
</template>

<script>
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
    //import 'swiper/css/swiper.css'

    export default {
        name: 'MainCarousels3',
        components: {
            Swiper,
            SwiperSlide
        },

        data: () => ({
            swiperOptionTop: {
                loop: true,
                loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                autoplay: {
                    delay: 5000,
                },
            },
            swiperOptionThumbs: {
                loop: true,
                loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10,
                centeredSlides: true,
                slidesPerView: 'auto',
                touchRatio: 0.2,
                slideToClickedSlide: true
            },
        }),
        mounted() {
            this.$nextTick(() => {
                const swiperTop = this.$refs.swiperTop.$swiper
                const swiperThumbs = this.$refs.swiperThumbs.$swiper
                swiperTop.controller.control = swiperThumbs
                swiperThumbs.controller.control = swiperTop
            })
        },
        computed: {},
        methods: {
            moreInfo(){

                this.$router.push({path: "/place/room/9"});
            }
        }
    }
</script>

<style lang="scss" scoped>
    .thumb-example {
        /*height: 400px;*/
        /*background-color: black;*/
    }

    .swiper {
        .swiper-slide {
            background-size: cover;
            background-position: center;

            &.slide-1 {
                background-image: url('../assets/place/pool1.jpg')
            }

            &.slide-2 {
                background-image: url('../assets/place/pool2.jpg')
            }

            &.slide-3 {
                background-image: url('../assets/place/pool3.jpg')
            }

            &.slide-4 {
                background-image: url('../assets/place/pool4.jpg')
            }

            &.slide-5 {
                background-image: url('../assets/place/pool5.jpg')
            }
        }

        &.gallery-top {
            /*height: 100%;*/
            height: 400px;
            width: 100%;
            float: left;
        }

        &.gallery-thumbs {
            /*height: 20%;*/
            height: 80px;
            width: 100%;
            box-sizing: border-box;
            padding: 0;
            float: right;
        }

        &.gallery-thumbs .swiper-slide {
            width: 20%;
            height: 100%;
            opacity: 0.4;
        }

        &.gallery-thumbs .swiper-slide-active {
            opacity: 1;
        }

        &.gallery-depiction {
            height: 320px;
            width: 100%;
            float: right;
        }
        &.gallery-depiction-sm {
            height: 100%;
            width: 100%;
            float: right;
        }

    }
</style>
