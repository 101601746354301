import Vue from 'vue'
import Vuex from 'vuex'

import room from './modules/store_room'
import userGuide from './modules/store_userGuide'
import reservation from './modules/store_reservation'
import resvConfirm from './modules/store_resvConfirm'
import admin from './modules/store_admin'
import board from './modules/store_board'
import pop from './modules/store_pop'
import acc from './modules/store_acc'
import sms from './modules/store_sms'
import imgInfo from './modules/store_img'

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        room,
        userGuide,
        reservation,
        resvConfirm,
        admin,
        board,
        pop,
        acc,
        sms,
        imgInfo,
    },
    getters: {
        room: state => state.room,
        userGuide: state => state.userGuide,
        reservation: state => state.reservation,
        resvConfirm: state => state.resvConfirm,
        admin: state => state.admin,
        board: state => state.board,
        pop: state => state.pop,
        acc: state => state.acc,
        sms: state => state.sms,
        imgInfo: state => state.imgInfo,
    },
    plugins: [createPersistedState({
            // 작성한 모듈만 저장
             //paths: ["room"],
    })],

})

import { mapGetters , mapState, mapMutations, mapActions   } from 'vuex'

var MyPlugin = {};
MyPlugin.install = function(Vue, options) {
    Vue.mixin({
        methods: {
            ...mapMutations('user',[
            ]),
        },
        computed : {
            ...mapGetters([
                'room',
                'userGuide',
                'reservation',
                'resvConfirm',
                'admin',
                'board',
                'pop',
                'acc',
                'sms',
                'imgInfo',

            ]),
        }
    });
}

Vue.use(MyPlugin);

export default store

/*export default new Vuex.Store({

    state: {},
    mutations: {},
    actions: {},

})*/
