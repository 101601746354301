import Vue from 'vue'
import axios from 'axios'
import api from "@/api/index"
//import api from "@/api/index"

const resvConfirm = {
    namespaced: true,
    state: {
        resvConfirmList: [],
        resvConfirmInfo: {},
        resvUsrInfo: {},


    },
    mutations: {
        SAVE: (state, info) => {
            try {
                // 1. default setting

                if (true) {
                    //data.list = set_default(data.message);

                    // 2. 저장소 적용
                    Vue.set(state, 'resvConfirmList', info.message);
                } else {
                    //alert(data.result)
                    Vue.set(state, 'resvConfirmList', []);
                }

            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , LOAD: (state, info) => {

        }
        , CLEAR({state}) {
        }
        , SAVE_RESV_CONFIRM_INFO: (state, info) => {
            try {
                // 1. default setting

                if (true) {
                    //data.list = set_default(data.message);

                    // 2. 저장소 적용
                    Vue.set(state, 'resvConfirmInfo', info.message[0]);
                } else {
                    //alert(data.result)
                    Vue.set(state, 'resvConfirmInfo', {});
                }

            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , SAVE_RESV_USR_INFO: (state, info) => {
            try {
                Vue.set(state, 'resvUsrInfo', info);

            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , CLEAR_RESV_USR_INFO: (state) => {
            try {
                Vue.set(state, 'resvUsrInfo', {});

            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
    },

    actions: {
        getList({commit, dispatch, getters}, fdata){
            return new Promise((resolve, reject) => {
                api.resv.resvConfirmList(fdata)
                    .then(function (resp) {
                        commit("SAVE", resp);
                        resolve(resp);

                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        }
        , getInfo({commit, dispatch, getters}, fdata){
            return new Promise((resolve, reject) => {

                api.resv.resvConfirmList(fdata)
                    .then(function (resp) {
                        commit("SAVE_RESV_CONFIRM_INFO", resp);
                        resolve(resp);

                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        }
        , cancelResv({commit, dispatch, getters}, fdata){
            return new Promise((resolve, reject) => {

                api.resv.cancelResv(fdata)
                    .then(function (resp) {
                        resolve(resp);

                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        }
    }
    , getters: {
        // isLogin : state => state._id == "" ? false : true
    }
}

export default resvConfirm
