import axios from '@/api/api.core.js'
import store from '@/store'

let api = "/api/admin1/sms";

function getSmsList(a_data) {
    var formdata = (a_data);
    return axios({
        url: api+"/getList.do",
        method: 'get',
        params: formdata,

    })
}

function getSmsInfo(a_data) {
    var formdata = (a_data);
    return axios({
        url: api+"/getInfo.do",
        method: 'get',
        params: formdata,

    })
}


function updateSms(a_data) {
    return axios({
        url: api+"/update.do",
        method: 'post',
        data: a_data,

    })
}


function deleteSms(a_data) {
    return axios({
        url: api+"/delete.do",
        method: 'post',
        data: a_data,
    })
}

// 문자전송
function sendSms(a_data) {
    return axios({
        url: api+"/send.do",
        method: 'post',
        data: a_data,
    })
}


export default {
    getSmsList,
    getSmsInfo,
    updateSms,
    deleteSms,
    sendSms,

}


