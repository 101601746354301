<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-row>
        <v-col
                cols="12"
                md="8">
            <v-row>
                <v-col cols="10">
                    <h2 class="pa-2 photo-title float-left">포토갤러리</h2>
                    <v-btn
                            class="ma-2 mt-6 float-left"
                            outlined
                            color="indigo"
                            x-small
                            @click="addClick(2)"
                    >
                        더보기
                    </v-btn>
                </v-col>
                <v-col cols="2">

                </v-col>
            </v-row>
            <v-row>

                <v-col
                        v-for="(item,n) in photoList"
                        :key="n"
                        class="d-flex child-flex"
                        @click="rowClick(item)"
                        cols="6" sm="4">
                    <v-card>
                        <v-img
                                :src="item.boardThumb"
                                :lazy-src="item.boardThumb"
                                aspect-ratio="1"
                                class="grey lighten-2"
                        ></v-img>
                    </v-card>

                </v-col>
            </v-row>


        </v-col>
        <v-col
                cols="12"
                md="4">
            <v-row>
                <v-col cols="10">
                    <h2 class="pa-2 photo-title float-left">공지사항</h2>
                    <v-btn
                            class="ma-2 mt-6 float-left"
                            outlined
                            color="indigo"
                            x-small
                            @click="addClick(1)"
                    >
                        더보기
                    </v-btn>
                </v-col>
                <v-col cols="2">

                </v-col>
            </v-row>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left notice-title">
                            제목
                        </th>
                        <th class="text-left notice-date">
                            날짜
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                            v-for="item in noticeList"
                            :key="item.boardId"
                            @click="rowClick(item)"
                    >
                        <td>{{ item.boardTitle }}</td>
                        <td>{{ item.lstModDate }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
</template>

<script>

    export default {
        name: 'MainNotice',
        data: () => ({
            desserts: [
                {
                    name: '공지사항1',
                    date: '2021-06-16',
                },
                {
                    name: '공지사항12',
                    date: '2021-06-16',
                },
                {
                    name: '공지사항13',
                    date: '2021-06-16',
                },
                {
                    name: '공지사항14',
                    date: '2021-06-16',
                },

            ],
            noticeList: [],
            photoList: [],


        }),
        mounted() {

        },
        created() {

            this.fetch()

        },
        methods: {
            fetch() {

                let formdata = {
                    boardGubun: '1'
                };

                return this.$store.dispatch("board/getBoardList", formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.noticeList = resp.message.slice(0, 4);
                            formdata = {
                                boardGubun: '2'
                            };

                            return this.$store.dispatch("board/getBoardList", formdata)
                                .then((resp) => {
                                    setTimeout(() => {
                                        this.photoList = resp.message.slice(0.,3);
                                    }, 300)
                                })
                                .catch((err) => {
                                })

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            rowClick(row){
                let fdata = {
                    boardId: row.boardId
                };

                return this.$store.dispatch("board/updateHitCnt", fdata)
                    .then((resp) => {
                        setTimeout(() => {
                            this.$store.commit("board/SAVE_BOARD_ID", row.boardId);
                            this.$router.push({path: "/community/read"});
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            addClick(val){

                switch (val) {
                    case 1 : {
                        this.$router.push({path: "/community/notice"});
                        break;
                    }
                    case 2 : {
                        this.$router.push({path: "/community/photo"});
                        break;
                    }

                }

            }

        },
        computed: {},
    }
</script>

