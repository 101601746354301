import axios from '@/api/api.core.js'
import store from '@/store'

let api = "/api/pop";

// 팝업 리스트
function getPopList(a_data) {
    var formdata = (a_data);
    return axios({
        url: api+"/getList.do",
        method: 'get',
        params: formdata,

    })
}

// 팝업 정보
function getPopInfo(a_data) {
    var formdata = (a_data);
    return axios({
        url: api+"/getInfo.do",
        method: 'get',
        params: formdata,

    })
}

// 팝업 수정
function updatePop(a_data) {
    return axios({
        url: api+"/update.do",
        method: 'post',
        data: a_data,

    })
}

// 팝업 삭제
function deletePop(a_data) {
    return axios({
        url: api+"/delete.do",
        method: 'post',
        data: a_data,
    })
}



export default {
    getPopList,
    getPopInfo,
    updatePop,
    deletePop,
}


