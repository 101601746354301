import axios from '@/api/api.core.js'
import store from '@/store'

let api = "/api/admin1";

// 로그인
function login(a_data) {
    var formdata = (a_data);

    return axios({
        //url: api + "/login.do",
        url:"/auth/login.do",
        method: 'get',
        params: formdata,

    })
}

// 접속정보
function getAcc(a_data) {
    var formdata = (a_data);
    return axios({
        url: api + "/getAcc.do",
        method: 'get',
        params: formdata,

    })
}

// 사용자정보 수정
function updateUsr(a_data) {

    return axios({
        url: api + "/usr/update.do",
        method: 'post',
        data: a_data,

    })
}

// 공통정보 가져오기
function getComn(a_data) {

    return axios({
        url: api + "/comn/get.do",
        method: 'post',
        data: a_data,

    })
}

// 공통정보 업데이트
function updateComn(a_data) {

    return axios({
        url: api + "/comn/update.do",
        method: 'post',
        data: a_data,

    })
}

// 에약정보
function getResvList(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/resv/getList.do",
        method: 'get',
        params: formdata,

    })
}

// 예약수 (메뉴)
function getResvCnt(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/resv/getCnt.do",
        method: 'get',
        params: formdata,

    })
}

function getResvListToDate(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/resv/getListToDate.do",
        method: 'get',
        params: formdata,

    })
}

function getResvListToDateExcel(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/excel/resv/getListToDate.do",
        method: 'get',
        params: formdata,

    })
}

function getList(a_data){
    var fromdata = (a_data);

}

function getResvInfo(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/resv/getInfo.do",
        method: 'get',
        params: formdata,

    })
}


function updateResv(a_data) {

    return axios({
        url: api + "/resv/update.do",
        method: 'post',
        data: a_data,

    })
}

function updateResvs(a_data) {

    return axios({
        url: api + "/resv/updates.do",
        method: 'post',
        data: a_data,

    })
}

function updateResv5(a_data) {

    return axios({
        url: api + "/resv/update/5.do",
        method: 'post',
        data: a_data,

    })
}

function delResv(a_data) {

    return axios({
        url: api + "/resv/delete.do",
        method: 'get',
        params: a_data,

    })
}

function getMonthList(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/resv/getMonthList.do",
        method: 'get',
        params: formdata,

    })
}

function getRoomList(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/room/getList.do",
        method: 'get',
        params: formdata,

    })
}

function getRoomCnt(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/room/getCnt.do",
        method: 'get',
        params: formdata,

    })
}

function getRoomInfo(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/room/getInfo.do",
        method: 'get',
        params: formdata,

    })
}

function getRoomImg(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/img/room/get.do",
        method: 'get',
        params: formdata,

    })
}

function updateRoomImg(a_data) {

    return axios({
        url: api + "/img/room/update.do",
        method: 'post',
        data: a_data,

    })
}

function updateAllRoom(a_data) {

    return axios({
        url: api + "/room/all/update.do",
        method: 'post',
        data: a_data,

    })
}

function getCampInfo(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/room/camp/getInfo.do",
        method: 'get',
        params: formdata,

    })
}

function updateRoom(a_data) {

    return axios({
        url: api + "/room/update.do",
        method: 'post',
        data: a_data,

    })
}

function getHoliList(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/holi/getList.do",
        method: 'get',
        params: formdata,

    })
}

function insertHoli(a_data) {

    return axios({
        url: api + "/holi/insert.do",
        method: 'post',
        data: a_data,

    })
}


function updateHoli(a_data) {

    return axios({
        url: api + "/holi/update.do",
        method: 'post',
        data: a_data,

    })
}

function deleteHoli(a_data) {

    return axios({
        url: api + "/holi/delete.do",
        method: 'post',
        data: a_data,

    })
}

function getPeakList(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/peak/getList.do",
        method: 'get',
        params: formdata,

    })
}


function insertPeak(a_data) {

    return axios({
        url: api + "/peak/insert.do",
        method: 'post',
        data: a_data,

    })
}

function updatePeak(a_data) {

    return axios({
        url: api + "/peak/update.do",
        method: 'post',
        data: a_data,

    })
}

function deletePeak(a_data) {

    return axios({
        url: api + "/peak/delete.do",
        method: 'post',
        data: a_data,

    })
}

function deletePeaks(a_data) {

    return axios({
        url: api + "/peak/deletes.do",
        method: 'post',
        data: a_data,

    })
}

function getSaleList(a_data) {
    return axios({
        url: api + "/sale/getList.do",
        method: 'get',
        params: a_data,
    })
}

function insertSale(a_data) {
    return axios({
        url: api + "/sale/insert.do",
        method: 'post',
        data: a_data,
    })
}

function updateSale(a_data) {
    return axios({
        url: api + "/sale/update.do",
        method: 'post',
        data: a_data,
    })
}

function deleteSale(a_data) {
    return axios({
        url: api + "/sale/delete.do",
        method: 'post',
        data: a_data,
    })
}

function deleteSales(a_data) {
    return axios({
        url: api + "/sale/deletes.do",
        method: 'post',
        data: a_data,
    })
}

function getStopList(a_data) {
    return axios({
        url: api + "/stop/getList.do",
        method: 'get',
        params: a_data,
    })
}

function insertStop(a_data) {
    return axios({
        url: api + "/stop/insert.do",
        method: 'post',
        data: a_data,
    })
}

function updateStop(a_data) {
    return axios({
        url: api + "/stop/update.do",
        method: 'post',
        data: a_data,
    })
}

function deleteStop(a_data) {
    return axios({
        url: api + "/stop/delete.do",
        method: 'post',
        data: a_data,
    })
}

function deleteStops(a_data) {
    return axios({
        url: api + "/stop/deletes.do",
        method: 'post',
        data: a_data,
    })
}


function getCampItemList(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/campItem/getList.do",
        method: 'get',
        params: formdata,

    })
}

function updateCampItem(a_data) {

    return axios({
        url: api + "/campItem/update.do",
        method: 'post',
        data: a_data,

    })
}

function deleteCampItem(a_data) {

    return axios({
        url: api + "/campItem/delete.do",
        method: 'post',
        data: a_data,

    })
}

function getHomeInfo(a_data) {

    return axios({
        url: api + "/home/get.do",
        method: 'get',
        params: a_data,

    })
}

function updateHomeInfo(a_data) {

    return axios({
        //url: api + "/home/update.do",
        url:"/auth/home/update.do",
        method: 'post',
        data: a_data,

    })
}

function getStatSum(a_data) {

    return axios({
        url: api + "/stat/sum.do",
        method: 'get',
        params: a_data,

    })
}

function getStatMonth(a_data) {

    return axios({
        url: api + "/stat/month.do",
        method: 'get',
        params: a_data,

    })
}

function getStatWeek(a_data) {

    return axios({
        url: api + "/stat/week.do",
        method: 'get',
        params: a_data,

    })
}

function getStatChart(a_data) {

    return axios({
        url: api + "/stat/chart.do",
        method: 'get',
        params: a_data,

    })
}

function getCountMain(a_data) {

    return axios({
        url: api + "/cou1nt/main.do",
        method: 'get',
        params: a_data,

    })
}

function getCountMonth(a_data) {

    return axios({
        url: api + "/cou1nt/month.do",
        method: 'get',
        params: a_data,

    })
}

function getStatDesc(a_data) {

    return axios({
        url: api + "/stat/desc.do",
        method: 'get',
        params: a_data,

    })
}

function getStatResvitDesc(a_data) {

    return axios({
        url: api + "/stat/resvit/desc/.do",
        method: 'get',
        params: a_data,

    })
}

function getStatPayment(a_data) {

    return axios({
        url: api + "/stat/payment.do",
        method: 'get',
        params: a_data,

    })
}

export default {
    login,
    getAcc,
    updateUsr,
    getComn,
    updateComn,
    getResvList,
    getResvCnt,
    getResvListToDate,
    getResvListToDateExcel,
    getResvInfo,
    updateResv,
    updateResvs,
    updateResv5,
    delResv,
    getMonthList,
    getRoomList,
    getRoomCnt,
    getRoomInfo,
    getRoomImg,
    updateRoomImg,
    updateRoom,
    updateAllRoom,
    getCampInfo,

    getHoliList,
    insertHoli,
    updateHoli,
    deleteHoli,
    getPeakList,
    insertPeak,
    updatePeak,
    deletePeak,
    deletePeaks,
    getSaleList,
    insertSale,
    updateSale,
    deleteSale,
    deleteSales,
    getStopList,
    insertStop,
    updateStop,
    deleteStop,
    deleteStops,
    getCampItemList,
    updateCampItem,
    deleteCampItem,
    getHomeInfo,
    updateHomeInfo,

    getStatSum,
    getStatMonth,
    getStatWeek,
    getStatChart,
    getCountMain,
    getCountMonth,
    getStatDesc,
    getStatResvitDesc,
    getStatPayment,



}


