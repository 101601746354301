import Vue from 'vue'
import axios from 'axios'
import api from "@/api/index"
//import api from "@/api/index"

const pop = {
    namespaced: true,
    state: {
        popId: "",
        popGubun: "",
        popInfo: {},
        popList: []


    },
    mutations: {
        LOAD: (state, info) => {

        }
        , CLEAR({state}) {
        }
        , SAVE_POP_GUBUN: (state, info) => {
            try {
                // 1. default setting

                Vue.set(state, 'popGubun', info);

            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , SAVE_POP_ID: (state, info) => {
            try {
                // 1. default setting

                Vue.set(state, 'popId', info);

            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , CLEAR_POP_ID(state) {
            Vue.set(state, 'popId', "");
        }
        , SAVE_POP_LIST: (state, info) => {
            try {
                // 1. default setting

                Vue.set(state, 'popList', info.message);

            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , SAVE_POP_INFO: (state, info) => {
            try {
                // 1. default setting
                Vue.set(state, 'popInfo', info.message);

            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }

    },

    actions: {
        getPopList({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.pop.getPopList(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {

                            commit("SAVE_POP_LIST", resp);
                            resolve(resp);
                        } else {

                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getPopInfo({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.pop.getPopInfo(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            commit("SAVE_POP_INFO", resp);
                            resolve(resp);
                        } else {

                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updatePop({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.pop.updatePop(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            commit("SAVE_POP_INFO", resp);
                            commit("SAVE_POP_ID", resp.message.popId);
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },

        deletePop({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.pop.deletePop(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            //commit("LOGIN", resp);
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },


    }
    , getters: {
        // isLogin : state => state._id == "" ? false : true
    }
}

export default pop
