import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
//import 'swiper/css/swiper.css'
import common from './assets/js/common'
import AOS from 'aos'
import 'aos/dist/aos.css'

//import  nhn from'./plugins/smarteditor2/js/HuskyEZCreator'
//import './plugins/smarteditor2-2.10.0/workspace/static/js/service/HuskyEZCreator.js'
// toast-ui
//import 'tui-editor/dist/tui-editor.css'
//import 'tui-editor/dist/tui-editor-contents.css'
//import 'codemirror/lib/codemirror.css'
//import { Editor, Viewer } from '@toast-ui/vue-editor'

// ...
//Vue.component('editor', Editor)
//Vue.component('viewer', Viewer)

import 'swiper/css/swiper.css'

Vue.filter('comma', function (val) {
    return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});


Vue.filter('de0', function (val) {
    if(val === undefined) val = 0;
    return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

Vue.config.productionTip = false;

Vue.use(common);

new Vue({
    router,
    store,
    vuetify,
    mounted () {
        AOS.init({
            easing: 'my-easing',
            duration: 500,
            delay: 50
        });
    },
    render: h => h(App)
}).$mount('#app')

