import axios from '@/api/api.core.js'
import store from '@/store'

let api = "/api/room";


function getRoomList(a_data) {
    var formdata = (a_data);

    return axios({
        url: api+"/getList.do",
        method: 'get',
        params: formdata,

    })
}

function getRoomInfo(a_data) {
    var formdata = (a_data);

    return axios({
        url: api+"/getList.do",
        method: 'get',
        params: formdata,

    })
}

function updateRoom(a_data) {

    return axios({
        url: api+"/update.do",
        method: 'post',
        data: a_data,

    })
}

function getCampList(a_data) {
    var formdata = (a_data);
    return axios({
        url: api+"/getCampList.do",
        method: 'get',
        params: formdata,

    })
}

// 룸 ITEM (아이콘) 가져오기
function getRoomItemList(a_data) {
    var formdata = (a_data);

    return axios({
        url: api + "/item/getList.do",
        method: 'get',
        params: formdata,

    })
}


function updateRoomItem(a_data) {

    return axios({
        url: api + "/item/update.do",
        method: 'post',
        data: a_data,

    })
}

function deleteRoomItem(a_data) {

    return axios({
        url: api + "/item/delete.do",
        method: 'post',
        data: a_data,

    })
}

export default {
    getRoomList,
    getRoomInfo,
    updateRoom,
    getCampList,

    getRoomItemList,
    updateRoomItem,
    deleteRoomItem,

}


