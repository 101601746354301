import axios from '@/api/api.core.js'
import store from '@/store'

let api = "/api/img";

function getImgList(a_data) {
    var formdata = (a_data);
    return axios({
        url: api+"/getList.do",
        method: 'get',
        params: formdata,

    })
}

function getImgInfo(a_data) {
    var formdata = (a_data);
    return axios({
        url: api+"/getInfo.do",
        method: 'get',
        params: formdata,

    })
}


function updateImg(a_data) {
    return axios({
        url: api+"/update.do",
        method: 'post',
        data: a_data,

    })
}

// 이미지 여러개 업데이트
function updatesImg(a_data) {
    return axios({
        url: api+"/updates.do",
        method: 'post',
        data: a_data,

    })
}


function deleteImg(a_data) {
    return axios({
        url: api+"/delete.do",
        method: 'post',
        data: a_data,
    })
}



export default {
    getImgList,
    getImgInfo,
    updateImg,
    updatesImg,
    deleteImg,
}


