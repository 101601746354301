<template>
    <div id="app">
        <!--<v-carousel
                :continuous="true"
                cycle
                show-arrows-on-hover
                hide-delimiter-background
                delimiter-icon="mdi-minus"
        >
            <v-carousel-item
                    v-for="(item,i) in items"
                    :key="i"
                    :src="item.src"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                    height
            >
            </v-carousel-item>
        </v-carousel>-->
        <swiper class="swiper" :options="swiperOption">
            <swiper-slide class="slide-1"></swiper-slide>
            <swiper-slide class="slide-2"></swiper-slide>
            <swiper-slide class="slide-3"></swiper-slide>
            <swiper-slide class="slide-4"></swiper-slide>
            <swiper-slide class="slide-5"></swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
        </swiper>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    //import 'swiper/css/swiper.css'

    export default {
        name: 'MainCarousels',
        components: {
            Swiper,
            SwiperSlide
        },

        data: () => ({
            items: [
                {
                    src: require('../assets/slider/mimg01.jpg'),
                },
                {
                    src: require('../assets/slider/mimg02.jpg'),
                },
                {
                    src: require('../assets/slider/mimg03.jpg'),
                },
                {
                    src: require('../assets/slider/mimg04.jpg'),
                },
                {
                    src: require('../assets/slider/mimg05.jpg'),
                },
                {
                    src: require('../assets/slider/mimg06.jpg'),
                },
            ],
            swiperOption: {
                spaceBetween: 30,
                effect: 'fade',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                autoplay: {
                    delay: 5000,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                loop: true,
            },
        }),
        computed: {},
    }
</script>

<style lang="scss" scoped>
    /*@import './base.scss';*/

    .swiper {
        height: 600px;

        .swiper-slide {
            background-position: center;
            background-size: cover;

            &.slide-1 {
                background-image:url('../assets/slider/mimg01.jpg')
            }
            &.slide-2 {
                background-image:url('../assets/slider/mimg02.jpg')
            }
            &.slide-3 {
                background-image:url('../assets/slider/mimg03.jpg')
            }
            &.slide-4 {
                background-image:url('../assets/slider/mimg04.jpg')
            }
            &.slide-5 {
                background-image:url('../assets/slider/mimg05.jpg')
            }
        }
    }
</style>
