import Vue from 'vue'
import axios from 'axios'
import api from "@/api/index"
//import api from "@/api/index"

const sms = {
    namespaced: true,
    state: {



    },
    mutations: {
        LOAD: (state, info) => {

        }
        , CLEAR({state}) {
        }


    },

    actions: {
        getSmsInfo({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.sms.getSmsInfo(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }

                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateSms({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {
                api.sms.updateSms(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },

        deleteSms({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {
                api.sms.deleteSms(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getSmsMonth({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {
                api.sms.getSmsMonth(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getSmsDay({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {
                api.sms.getSmsDay(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getSmsTime({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {
                api.sms.getSmsTime(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getSmsWeek({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {
                api.sms.getSmsWeek(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getSmsEng({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {
                api.sms.getSmsEng(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        sendSms({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {
                api.sms.sendSms(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
    }
    , getters: {
        // isLogin : state => state._id == "" ? false : true
    }
}

export default sms
