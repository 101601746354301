import axios from '@/api/api.core.js'
import store from '@/store'

let api = "/api/admin1/acc";

// 접속정보 리스트
function getAccList(a_data) {
    var formdata = (a_data);
    return axios({
        url: api+"/getList.do",
        method: 'get',
        params: formdata,

    })
}

function getAccInfo(a_data) {
    var formdata = (a_data);
    return axios({
        url: api+"/getInfo.do",
        method: 'get',
        params: formdata,

    })
}

// 접속정보 업데이트
function updateAcc(a_data) {
    return axios({
        url: api+"/update.do",
        method: 'post',
        data: a_data,

    })
}


function deleteAcc(a_data) {
    var formdata = (a_data);

    return axios({
        url: api+"/delete.do",
        method: 'post',
        data: a_data,
    })
}

// 접속통계 월별
function getAccMonth(a_data) {
    var formdata = (a_data);

    return axios({
        url: api+"/month.do",
        method: 'get',
        params: formdata,
    })
}

// 접속통계 일별
function getAccDay(a_data) {
    var formdata = (a_data);

    return axios({
        url: api+"/day.do",
        method: 'get',
        params: formdata,
    })
}

// 접속통계 시간대별
function getAccTime(a_data) {
    var formdata = (a_data);

    return axios({
        url: api+"/time.do",
        method: 'get',
        params: formdata,
    })
}

// 접속통계 요일별
function getAccWeek(a_data) {
    var formdata = (a_data);

    return axios({
        url: api+"/week.do",
        method: 'get',
        params: formdata,
    })
}

//  접속통계 검색엔진별
function getAccEng(a_data) {
    var formdata = (a_data);

    return axios({
        url: api+"/eng.do",
        method: 'get',
        params: formdata,
    })
}



export default {
    getAccList,
    getAccInfo,
    updateAcc,
    deleteAcc,

    getAccMonth,
    getAccDay,
    getAccTime,
    getAccWeek,
    getAccEng,
}


