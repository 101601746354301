<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <router-view/>
</template>

<script>

    export default {

        name: 'App',
        data: () => ({


        }),
        methods: {

            log() {
            }
        },

        watch: {
            group() {
            },

        },
    };

</script>

<style>
    @import './assets/styles/common.css';

</style>
