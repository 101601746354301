


import core from './api.core'
import room from './room'
import resv from './resv'
import admin from './admin'
import board from './board'
import pop from './pop'
import acc from './acc'
import sms from './sms'
import imgInfo from './imgInfo'

export default {
    core
    ,room
    ,resv
    ,admin
    ,board
    ,pop
    ,acc
    ,sms
    ,imgInfo
}
