import Vue from 'vue'
import axios from 'axios'
import api from "@/api/index"

const room = {
    namespaced: true,
    state: {

        roomInfo: {
          roomId: "",
          roomGubun: "",
        },
        roomList: [],

        roomData: {
            id: 1,
            name: "장미",
            acreage: "30평(99.17㎡)",
            form: "방2,거실,욕실2",
            stdNop: "9명",
            maxNop: "12명",
            osrWe: "250,000원",
            osrWd: "250,000원",
            spsrWe: "250,000원",
            spsrWd: "300,000원",
            psrWe: "350,000원",
            psrWd: "350,000원",
            roomImgs: [
                {"route": 'room1-1.jpg'},
                {"route": 'room1-2.jpg'},
                {"route": 'room1-3.jpg'},
                {"route": 'room1-4.jpg'},
            ]
        },
        roomData1: {
            id: 1,
            name: "장미",
            acreage: "30평(99.17㎡)",
            form: "방2,거실,욕실2",
            stdNop: "9명",
            maxNop: "12명",
            osrWe: "250,000원",
            osrWd: "250,000원",
            spsrWe: "250,000원",
            spsrWd: "300,000원",
            psrWe: "350,000원",
            psrWd: "350,000원",
            roomImgs: [
                {"route": 'room1-1.jpg'},
                {"route": 'room1-2.jpg'},
                {"route": 'room1-3.jpg'},
                {"route": 'room1-4.jpg'},
            ]
        },
        roomData2: {
            id: 2,
            name: "별님",
            acreage: "20평(66.12㎡)",
            form: "온돌방,거실,욕실",
            stdNop: "6명",
            maxNop: "8명",
            osrWe: "200,000원",
            osrWd: "200,000원",
            spsrWe: "200,000원",
            spsrWd: "250,000원",
            psrWe: "250,000원",
            psrWd: "250,000원",
            roomImgs: [
                {"route": 'room2-1.jpg'},
                {"route": 'room2-2.jpg'},
                {"route": 'room2-3.jpg'},
                {"route": 'room2-4.jpg'},
            ]
        },
        roomData3: {
            id: 3,
            name: "달님",
            acreage: "20평(66.12㎡)",
            form: "온돌방,거실,욕실",
            stdNop: "6명",
            maxNop: "8명",
            osrWe: "200,000원",
            osrWd: "200,000원",
            spsrWe: "220,000원",
            spsrWd: "250,000원",
            psrWe: "250,000원",
            psrWd: "250,000원",
            roomImgs: [
                {"route": 'room3-1.jpg'},
                {"route": 'room3-2.jpg'},
                {"route": 'room3-3.jpg'},
                {"route": 'room3-4.jpg'},
            ]
        },
        roomData4: {
            id: 4,
            name: "백합",
            acreage: "10평(33.06㎡)",
            form: "",
            stdNop: "5명",
            maxNop: "7명",
            osrWe: "200,000원",
            osrWd: "200,000원",
            spsrWe: "200,000원",
            spsrWd: "250,000원",
            psrWe: "250,000원",
            psrWd: "250,000원",
            roomImgs: [
                {"route": 'room4-1.jpg'},
                {"route": 'room4-2.jpg'},
            ]
        },
        roomData5: {
            id: 5,
            name: "하늘",
            acreage: "17평(56.20㎡)",
            form: "온돌방,거실,욕실",
            stdNop: "4명",
            maxNop: "5명",
            osrWe: "150,000원",
            osrWd: "150,000원",
            spsrWe: "150,000원",
            spsrWd: "200,000원",
            psrWe: "200,000원",
            psrWd: "200,000원",
            roomImgs: [
                {"route": 'room5-1.jpg'},
                {"route": 'room5-2.jpg'},
                {"route": 'room5-3.jpg'},
                {"route": 'room5-4.jpg'},
                {"route": 'room5-5.jpg'},
                {"route": 'room5-6.jpg'},
                {"route": 'room5-7.jpg'},
            ]
        },
        roomData6: {
            id: 6,
            name: "구름",
            acreage: "17평(56.20㎡)",
            form: "온돌방,거실,욕실",
            stdNop: "4명",
            maxNop: "5명",
            osrWe: "150,000원",
            osrWd: "150,000원",
            spsrWe: "150,000원",
            spsrWd: "200,000원",
            psrWe: "200,000원",
            psrWd: "200,000원",
            roomImgs: [
                {"route": 'room6-1.jpg'},
                {"route": 'room6-2.jpg'},
                {"route": 'room6-3.jpg'},
                {"route": 'room6-4.jpg'},
                {"route": 'room6-5.jpg'},
            ]
        },
        roomData7: {
            id: 7,
            name: "캠핑장-A",

            roomImgs: [
                {"route": 'camp_a1.jpg'},
                {"route": 'camp_a2.jpg'},
                {"route": 'camp_a3.jpg'},
                {"route": 'camp_a4.jpg'},
                {"route": 'camp_a5.jpg'},
            ]
        },

        roomData8: {
            id: 8,
            name: "캠핑장-B",

            roomImgs: [
                {"route": 'camp_b1.jpg'},
                {"route": 'camp_b2.jpg'},
                {"route": 'camp_b3.jpg'},
                {"route": 'camp_b4.jpg'},
            ]
        },
        roomData9: {
            id: 9,
            name: "수영장",

            roomImgs: [
                {"route": 'pool1.jpg'},
                {"route": 'pool2.jpg'},
                {"route": 'pool3.jpg'},
                {"route": 'pool4.jpg'},
            ]
        },



    },
    mutations: {
        SAVE: (state, info) => {
            try {
                // 1. default setting

                if (true) {
                    //data.list = set_default(data.message);
                    // 2. 저장소 적용
                    Vue.set(state, 'roomData', info);
                } else {
                    //alert(data.result)
                    Vue.set(state, 'roomData', {});
                }
            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , LOAD: (state, info) => {
        }
        , CLEAR({state}) {
        }
        , SAVE_ROOM_INFO: (state, info) => {
            try {
                // 1. default setting

                if (info.roomId != undefined) {
                    //data.list = set_default(data.message);

                    // 2. 저장소 적용
                    Vue.set(state, 'roomInfo', info);
                } else {
                    //alert(data.result)
                    Vue.set(state, 'roomInfo', {});
                }
            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , CLEAR_ROOM_INFO: (state, info) => {
            try {
                // 1. default setting

                Vue.set(state, 'roomInfo', {roomId: "", roomGubun: "",});
            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
    },

    actions: {
        getRoomList({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.room.getRoomList(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getRoomInfo({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {
                api.room.getRoomInfo(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            commit("SAVE_ROOM_INFO", resp);
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getCampList({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.room.getCampList(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
    }
    , getters: {
        // isLogin : state => state._id == "" ? false : true
    }
}

export default room
