import axios from '@/api/api.core.js'
import store from '@/store'

let api = "/api/board";

// 게시판 리스트
function getBoardList(a_data) {
    var formdata = (a_data);
    return axios({
        url: api+"/getList.do",
        method: 'get',
        params: formdata,

    })
}

// 게시판 상세정보
function getBoardInfo(a_data) {
    var formdata = (a_data);
    return axios({
        url: api+"/getInfo.do",
        method: 'get',
        params: formdata,

    })
}

// 게시판 수정
function updateBoard(a_data) {
    return axios({
        url: api+"/update.do",
        method: 'post',
        data: a_data,

    })
}

// 게시판 조회수 증가
function updateHitCnt(a_data) {
    return axios({
        url: api+"/updateHitCnt.do",
        method: 'post',
        data: a_data,

    })
}

// 게시판 삭제
function deleteBoard(a_data) {

    return axios({
        url: api+"/delete.do",
        method: 'post',
        data: a_data,
    })
}

// 댓글추가
function insertComt(a_data) {
    return axios({
        url: api+"/comt/insert.do",
        method: 'post',
        data: a_data,

    })
}

// 댓글 삭제
function deleteComt(a_data) {
    return axios({
        url: api+"/comt/delete.do",
        method: 'post',
        data: a_data,
    })
}

// 게시글, 댓글 삭제 (관리자 전용)
function deleteAdmin(a_data) {
    return axios({
        url: api+"/admin/delete.do",
        method: 'post',
        data: a_data,
    })
}

export default {
    getBoardList,
    getBoardInfo,
    updateBoard,
    deleteBoard,
    updateHitCnt,

    insertComt,
    deleteComt,

    deleteAdmin,
}


