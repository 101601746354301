import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    /*{
        path: '/',
        name: 'Home',
        component: () => import( '../views/LayoutHome.vue'),
        children: [
            {
                path: '/',
                name: 'Home2',
                component: () => import( '../views/Home2.vue')
            },
        ]
    },*/ {    // 홈페이지
        path: '/',
        name: 'HomeLayout',
        component: () => import( '../views/LayoutHome.vue'),
        children: [
            {
                path: '/',
                name: 'Home2',
                component: () => import( '../views/Home2.vue')
            }, {
                path: '/about/main',
                name: 'AboutMain',
                component: () => import( '../views/about/AboutMain.vue')
            }, {
                path: '/about/intro',
                name: 'Intro',
                component: () => import( '../views/about/Intro.vue')
            }, {
                path: '/about/roadMap',
                name: 'RoadMap',
                component: () => import( '../views/about/RoadMap.vue')
            }, {
                path: '/userGuide/near',
                name: 'Near',
                component: () => import( '../views/about/Near.vue')
            }, {
                path: '/place/main',
                name: 'PlaceMain',
                component: () => import( '../views/place/PlaceMain.vue')
            }, {
                path: '/place/roomList',
                name: 'RoomList',
                component: () => import( '../views/place/RoomList.vue')
            }, {
                path: '/place/roomList/roomInfo',
                name: 'RoomInfo',
                component: () => import( '../views/place/RoomInfo.vue')
            }, {
                path: '/place/campList',
                name: 'CampList',
                component: () => import( '../views/place/CampList.vue')
            }, {
                path: '/place/campList/campInfo',
                name: 'CampInfo',
                component: () => import( '../views/place/CampInfo.vue')
            }, {
                path: '/userGuide/main',
                name: 'UseInfo',
                component: () => import( '../views/userGuide/UseInfo.vue')
            }, {
                path: '/community/notice',
                name: 'Notice',
                component: () => import( '../views/community/Notice.vue')
            }, {
                path: '/community/photo',
                name: 'Photo',
                component: () => import( '../views/community/Photo.vue')
            }, {
                path: '/community/review',
                name: 'Review',
                component: () => import( '../views/community/Review.vue')
            }, {
                path: '/community/customer',
                name: 'Customer',
                component: () => import( '../views/community/Customer.vue')
            }, {
                path: '/community/write',
                name: 'Write',
                component: () => import( '../views/community/Write.vue')
            }, {
                path: '/community/read',
                name: 'Read',
                component: () => import( '../views/community/Read.vue')
            }, {
                path: '/reservation/searchTmp',
                name: 'ReservationSearchTmp',
                component: () => import( '../views/reservation/Search_tmp.vue'),
                props: true
            }, {
                path: '/reservation/search',
                name: 'ReservationSearch',
                component: () => import( '../views/reservation/Search.vue'),
                props: true
            }, {
                path: '/reservation/paymentTmp',
                name: 'ReservationPaymentTmp',
                component: () => import( '../views/reservation/Payment_tmp.vue'),
                props: true
            }, {
                path: '/reservation/searchAdd',
                name: 'ReservationSearchAdd',
                component: () => import( '../views/reservation/Search_add.vue'),
                props: true
            }, {
                path: '/reservation/paymentConfirmTmp',
                name: 'ReservationPaymentConfirmTmp',
                component: () => import( '../views/reservation/PaymentConfirm_tmp.vue'),
                props: true
            }, {
                path: '/reservation/resvConfirm',
                name: 'ReservationConfirm',
                component: () => import( '../views/resvConfirm/ResvConfirm.vue')
            }, {
                path: '/reservation/resvConfirmList',
                name: 'ReservationConfirmList',
                component: () => import( '../views/resvConfirm/ResvConfirmList.vue')
            }, {
                path: '/reservation/resvConfirmInfo',
                name: 'ReservationConfirmInfo',
                component: () => import( '../views/resvConfirm/ResvConfirmInfo.vue')
            }, {
                path: '/login',
                name: 'AdminLogin',
                component: () => import( '../views/admin/Login.vue')
            },
        ]
    },
    {    // 관리자
        path: '/admin',
        name: 'Admin',
        component: () => import( '../views/LayoutAdmin.vue'),
        children: [
            {
                path: '/admin/login',
                name: 'AdminLogin',
                component: () => import( '../views/admin/Login.vue')
            },
            {
                path: '/admin/mainPage',
                name: 'AdminMainPage',
                component: () => import( '../views/admin/MainPage.vue')
            }, {
                path: '/admin/main',
                name: 'AdminMain',
                component: () => import( '../views/admin/Main.vue')
            }, {
                path: '/admin/smsInfo',
                name: 'AdminSmsInfo',
                component: () => import( '../views/admin/SmsInfo.vue')
            }, {
                path: '/admin/payInfo',
                name: 'AdminPayInfo',
                component: () => import( '../views/admin/PayInfo.vue')
            }, {
                path: '/admin/default',
                name: 'AdminDefault',
                component: () => import( '../views/admin/Default.vue')
            }, {
                path: '/admin/resv/list',
                name: 'AdminResvList',
                component: () => import( '../views/admin/ResvList.vue')
            }, /*{
                path: '/admin/resv/info',
                name: 'AdminResvInfo',
                component: () => import( '../views/admin/ResvInfo.vue')
            },*/ {
                path: '/admin/resv/calendar',
                name: 'AdminResvCal',
                component: () => import( '../views/admin/ResvCal.vue')
            }, {
                path: '/admin/room/list',
                name: 'AdminRoomList',
                component: () => import( '../views/admin/RoomList.vue')
            }, {
                path: '/admin/room/info',
                name: 'AdminRoomInfo',
                component: () => import( '../views/admin/RoomInfo.vue')
            }, {
                path: '/admin/holi/list',
                name: 'AdminHoliPeakList',
                component: () => import( '../views/admin/HoliPeakList.vue')
            }, {
                path: '/admin/sale/list',
                name: 'AdminSaleList',
                component: () => import( '../views/admin/SaleList.vue')
            }, {
                path: '/admin/stop/list',
                name: 'AdminStopList',
                component: () => import( '../views/admin/StopList.vue')
            }, {
                path: '/admin/campItem/list',
                name: 'AdminCampItemList',
                component: () => import( '../views/admin/CampItemList.vue')
            }, {
                path: '/admin/stat/main',
                name: 'AdminStatMain',
                component: () => import( '../views/admin/StatMain.vue')
            }, {
                path: '/admin/stat/month',
                name: 'AdminStatMonth',
                component: () => import( '../views/admin/StatMonth.vue')
            }, {
                path: '/admin/stat/chart',
                name: 'AdminStatChart',
                component: () => import( '../views/admin/StatChart.vue')
            }, {
                path: '/admin/count/main',
                name: 'AdminCountMain',
                component: () => import( '../views/admin/CountMain.vue')
            }, {
                path: '/admin/count/month',
                name: 'AdminStatCountMonth',
                component: () => import( '../views/admin/CountMonth.vue')
            }, {
                path: '/admin/stat/desc/count',
                name: 'AdminStatDescCount',
                component: () => import( '../views/admin/StatDescCount.vue')
            }, {
                path: '/admin/stat/desc/price',
                name: 'AdminStatDescPrice',
                component: () => import( '../views/admin/StatDescPrice.vue')
            }, {
                path: '/admin/stat/desc/payment',
                name: 'AdminStatDescPayment',
                component: () => import( '../views/admin/StatDescPayment.vue')
            }, {
                path: '/admin/pop/list',
                name: 'AdminPopList',
                component: () => import( '../views/admin/PopList.vue')
            }, {
                path: '/admin/pop/info',
                name: 'AdminPopInfo',
                component: () => import( '../views/admin/PopInfo.vue')
            }, {
                path: '/admin/room/item',
                name: 'AdminRoomItem',
                component: () => import( '../views/admin/RoomItem.vue')
            }, {
                path: '/admin/acc/month',
                name: 'AdminAccMonth',
                component: () => import( '../views/admin/AccMonth.vue')
            }, {
                path: '/admin/acc/day',
                name: 'AdminAccDay',
                component: () => import( '../views/admin/AccDay.vue')
            }, {
                path: '/admin/acc/time',
                name: 'AdminAccTime',
                component: () => import( '../views/admin/AccTime.vue')
            }, {
                path: '/admin/acc/week',
                name: 'AdminAccWeek',
                component: () => import( '../views/admin/AccWeek.vue')
            }, {
                path: '/admin/acc/search',
                name: 'AdminAccSearch',
                component: () => import( '../views/admin/AccSearch.vue')
            }, {
                path: '/admin/acc/path',
                name: 'AdminAccPath',
                component: () => import( '../views/admin/AccPath.vue')
            }, {
                path: '/admin/imgInfo',
                name: 'AdminImngInfo',
                component: () => import( '../views/admin/ImgInfo.vue')
            }, {
                path: '/admin/imgInfo2',
                name: 'AdminImngInfo2',
                component: () => import( '../views/admin/ImgInfo2.vue')
            }, {
                path: '/admin/imgInfo3',
                name: 'AdminImngInfo3',
                component: () => import( '../views/admin/ImgInfo3.vue')
            },
        ]
    },
    {    // 팝업
        path: '/popup',
        name: 'Popup',
        component: () => import( '../views/LayoutPopup.vue'),
        children: [
            {
                path: '/popup/resv/info',
                name: 'AdminResvInfo',
                component: () => import( '../views/admin/ResvInfo.vue')
            }, {
                path: '/popup/resv/listToDate',
                name: 'AdminResvListToDate',
                component: () => import( '../views/admin/ResvListToDate.vue')
            }, {
                path: '/popup/place/campMap',
                name: 'CampMapPop',
                component: () => import( '../views/place/CampMapPop.vue')
            }, {
                path: '/popup/place/roadMap',
                name: 'RoadMapPop',
                component: () => import( '../views/place/RoadMapPop.vue')
            }, {
                path: '/popup/read',
                name: 'PopRead',
                component: () => import( '../views/admin/PopRead.vue')
            }

        ]
    },
    /*{ // 페이지 없는 경우
        path: '*',
        name: 'noPage',
        component: () => import( '../views/LayoutHome.vue'),
        children: [
            {
                path: '/',
                name: 'Home1',
                component: () => import( '../views/Home.vue')
            }
        ]
    },*/

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
