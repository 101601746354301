import Vue from 'vue'
import axios from 'axios'
import api from "@/api/index"
//import api from "@/api/index"

const admin = {
    namespaced: true,
    state: {
        loginInfo: {
            usrId: "",
            usrName: "",
            usrLvl: "0",
        },
        isLogin: false,
        tabData: [
            {
                id: 0,
                name: "메인",
                route: "/admin/mainPage"
            },
            {
                id: 1,
                name: "기본설정",
                route: "/admin/main"
            }, {
                id: 2,
                name: "객실관리",
                route: "/admin/room/list",
            }, {
                id: 3,
                name: "예약관리",
                route: "/admin/resv/list",
            }, {
                id: 4,
                name: "게시판관리",
                route: "/admin/main"
            }, {
                id: 5,
                name: "예약통계",
                route: "/admin/main"
            }, {
                id: 6,
                name: "접속통계",
                route: "/admin/main"
            }
        ],
        roomInfo: {},
        roomGubun: 'all',
        homeInfo: {},


    },
    mutations: {
        LOGIN: (state, info) => {
            try {
                // 1. default setting
                if(info.message.usrId = 'admin'){
                    info.message.usrLvl = 9;
                }else {
                    info.message.usrLvl = 1;
                }

                Vue.set(state, 'loginInfo', info.message);
                Vue.set(state, 'isLogin', true);
            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        },
        LOGOUT: (state, info) => {
            try {
                // 1. default setting
                let loginInfo = {
                    usrId: "",
                    usrName: "",
                    usrLvl: "0",
                };

                Vue.set(state, 'loginInfo', loginInfo);
                Vue.set(state, 'isLogin', false);
            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , LOAD: (state, info) => {

        }
        , CLEAR({state}) {
        }
        , SAVE_ROOM_INFO: (state, info) => {
            try {
                // 1. default setting

                if (info.roomId != undefined) {
                    //data.list = set_default(data.message);

                    // 2. 저장소 적용
                    Vue.set(state, 'roomInfo', info);
                } else {
                    //alert(data.result)
                    Vue.set(state, 'roomInfo', {});
                }
            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , CLEAR_ROOM_INFO: (state, info) => {
            try {
                // 1. default setting

                Vue.set(state, 'roomInfo', {roomId: "", roomGubun: "",});
            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , SAVE_ROOM_GUBUN: (state, info) => {
            try {
                // 1. default setting

                if (info != undefined) {
                    //data.list = set_default(data.message);

                    // 2. 저장소 적용
                    Vue.set(state, 'roomGubun', info);
                } else {
                    //alert(data.result)
                    Vue.set(state, 'roomGubun', 'all');
                }
            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        ,SAVE_HOME_INFO: (state, info) => {
            try {
                // 1. default setting

                if (info != undefined) {
                    //data.list = set_default(data.message);

                    // 2. 저장소 적용
                    Vue.set(state, 'homeInfo', info);
                } else {
                    //alert(data.result)
                    Vue.set(state, 'homeInfo', {});
                }
            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }

    },

    actions: {
        login({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.login(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            commit("LOGIN", resp);
                            resolve(resp);
                        } else {
                            alert("아이디 또는 비밀번호를 다시 확인해주세요.");
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        logout({commit, dispatch, getters}) {
            commit("LOGOUT");
        },
        getAcc({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {
                api.admin.getAcc(fdata)
                    .then(function (resp) {


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },

        getResvList({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getResvList(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {

                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getResvCnt({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getResvCnt(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getResvListToDate({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getResvListToDate(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {

                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },



        getResvListToDateExcel({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getResvListToDateExcel(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {

                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getResvInfo({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getResvInfo(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {

                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateUsr({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.updateUsr(fdata)
                    .then(function (resp) {

                        resolve(resp);


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getComn({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getComn(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateComn({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.updateComn(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateResv({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.updateResv(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            alert(resp.message);
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateResvs({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.updateResvs(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            alert(resp.message);
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateResv5({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {
                api.admin.updateResv5(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            alert(resp.message);
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        delResv({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.delResv(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            alert(resp.message);
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getMonthList({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getMonthList(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getRoomList({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getRoomList(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getRoomCnt({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getRoomCnt(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getRoomInfo({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getRoomInfo(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            commit("SAVE_ROOM_INFO", resp.message[0]);
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getRoomImg({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getRoomImg(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateRoomImg({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.updateRoomImg(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateRoom({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.updateRoom(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            alert(resp.message);
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateAllRoom({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.updateAllRoom(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            alert(resp.message);
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getCampInfo({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getCampInfo(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            alert(resp.message);
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getHoliList({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getHoliList(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        insertHoli({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.insertHoli(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateHoli({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.updateHoli(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        deleteHoli({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.deleteHoli(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getPeakList({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getPeakList(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updatePeak({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.updatePeak(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        insertPeak({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.insertPeak(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        deletePeak({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.deletePeak(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        deletePeaks({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.deletePeaks(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getSaleList({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getSaleList(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        insertSale({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.insertSale(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateSale({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.updateSale(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        deleteSale({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.deleteSale(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        deleteSales({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.deleteSales(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },

        getStopList({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getStopList(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        insertStop({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.insertStop(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateStop({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.updateStop(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        deleteStop({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.deleteStop(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },

        deleteStops({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.deleteStops(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getCampItemList({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getCampItemList(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateCampItem({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.updateCampItem(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        deleteCampItem({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.deleteCampItem(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getHomeInfo({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getHomeInfo(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            commit("SAVE_HOME_INFO", resp.message);
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateHomeInfo({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.updateHomeInfo(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getStatSum({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getStatSum(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getStatMonth({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getStatMonth(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getStatWeek({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getStatWeek(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getStatChart({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getStatChart(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getCountMain({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getCountMain(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getCountMonth({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getCountMonth(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getStatDesc({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getStatDesc(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getStatResvitDesc({commit, dispatch, getters}, fdata) {
            return new Promise((resolve, reject) => {
                api.admin.getStatResvitDesc(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getStatPayment({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.admin.getStatPayment(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getRoomItemList({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.room.getRoomItemList(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateRoomItem({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.room.updateRoomItem(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        deleteRoomItem({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.room.deleteRoomItem(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },

    }
    , getters: {
        // isLogin : state => state._id == "" ? false : true
    }
}

export default admin
