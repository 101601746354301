
const methods = {
    // 관리자 로그인 확인
    isAdminCheck: (value) => {

        if (value) {
            //this.$router.push({path: "/reservation/select"});
        } else {
            alert("잘못된 접근입니다.");
            location.href = '/admin/login';
        }
    },
    // 리스트 정렬
    sortList(list, sortingField) {

        list.sort(function (a, b) { // 오름차순
            return a[sortingField] - b[sortingField];
            // 1, 2, 3, 4, 10, 11
        });

        return list;
    },
    getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    setPhoneNum(phone){
        return phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    },
    delPhoneHyphen(phone){
      return phone.replace(/-/g, "");
    },
    // 접속 디바이스 모바일인지 확인
    isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    // 핸드폰 정규식
    isHpFormat(hp) {
        if (hp == "") {
            return false;
        }
        let phoneRule = /(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
        return phoneRule.test(hp);
    },
    isHpFormatH(hp) {
        if (hp == "") {
            return false;
        }

        let phoneRule = '';

        if (hp.indexOf('-') != -1) {
            phoneRule = /01[016789]-[^0][0-9]{2,3}-[0-9]{3,4}/;
        } else {
            phoneRule = /(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
        }
        return phoneRule.test(hp);
    },
    setCookie(cname, cvalue, exdays) {

        var todayDate = new Date();
        todayDate.setTime(todayDate.getTime() + (exdays*24*60*60*1000));
        //todayDate.setTime(todayDate.getTime() + (exdays*10*1000));
        var expires = "expires=" + todayDate.toUTCString(); // UTC기준의 시간에 exdays인자로 받은 값에 의해서 cookie가 설정 됩니다.

        document.cookie = cname + "=" + cvalue + "; " + expires;

    },

    getCookie (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');

        for(var i=0; i<ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1);
            if (c.indexOf(name) != -1) return c.substring(name.length,c.length);
        }
        return "";
    },
    deleteCookie (name) {
        let domain = document.domain;
        let path = '/';

        const expiration = 'Sat, 01 Jan 1972 00:00:00 GMT';
        //document.cookie = name + '=; expires=Thu, 01 Jan 1999 00:00:10 GMT;';
        document.cookie = name + '=; expires=' + expiration + '; domain =' + domain + '; path=' + path;

        path = '/admin';
        document.cookie = name + '=; expires=' + expiration + '; domain =' + domain + '; path=' + path;
    },
    alldeleteCookie(domain, path) {

        // const doc = document;
        domain = domain || document.domain;
        path = path || '/';

        const cookies = document.cookie.split('; '); // 배열로 반환

        const expiration = 'Sat, 01 Jan 1972 00:00:00 GMT';

        // 반목문 순회하면서 쿠키 전체 삭제
        if (!document.cookie) {
            //alert('삭제할 쿠키가 없습니다.');
        } else {
            for (let i = 0; i < cookies.length; i++) {
                // const uname = cookies[i].split('=')[0];
                // document.cookie = `${uname}=; expires=${expiration}`;
                //document.cookie = cookies[i].split('=')[0] + '=; expires=' + expiration;
                 document.cookie = cookies[i].split('=')[0] + '=; expires=' + expiration + '; domain =' + domain + '; path=' + path;
            }
            //alert('쿠키 전부 삭제완료!!');
        }
    },
    // 접속 확인
    isAuthCheck: (value) => {
        if (value) {
            //methods.deleteCookie ("AUTH");
            //methods.setCookie("AUTH","true","1");
        } else {
            alert("잘못된 접근입니다.");
            location.href = '/admin/login';
        }
    },
    usrLvlCheck(){

        this.isAuthCheck(true);

    },
    getTodayYear(){
        var today = new Date();

        return today.getFullYear();
    }


};

export default {
    install(Vue) {
        Vue.prototype.$isAdminCheck = methods.isAdminCheck;
        Vue.prototype.$sortList = methods.sortList;
        Vue.prototype.$getParameterByName = methods.getParameterByName;
        Vue.prototype.$setPhoneNum = methods.setPhoneNum;
        Vue.prototype.$delPhoneHyphen = methods.delPhoneHyphen;
        Vue.prototype.$isMobile = methods.isMobile;
        Vue.prototype.$isHpFormat = methods.isHpFormat;
        Vue.prototype.$isHpFormatH = methods.isHpFormatH;
        Vue.prototype.$setCookie = methods.setCookie;
        Vue.prototype.$getCookie = methods.getCookie;
        Vue.prototype.$deleteCookie = methods.deleteCookie;
        Vue.prototype.$alldeleteCookie = methods.alldeleteCookie;
        Vue.prototype.$isAuthCheck = methods.isAuthCheck;
        Vue.prototype.$getTodayYear = methods.getTodayYear;

    }
}
