<template>
    <div>


        <v-row>
            <v-col style="padding:12px 12px 8px 12px;">
                <!--<v-container>
                <main-carousels></main-carousels>
                </v-container>-->
                <main-carousels></main-carousels>
            </v-col>
        </v-row>
        <v-row
                dense
                class="fill-height mb-4 bg-main-button"
                align="center"
                justify="center"
        >
            <v-col>
                <v-container>
                    <v-row>
                        <template v-for="(item, i) in items">
                            <v-col
                                    md="2"
                                    :key="i"
                                    cols="6"
                            >
                                <v-hover v-slot="{ hover }">
                                    <v-card
                                            :elevation="hover ? 12 : 2"
                                            :class="{ 'on-hover': hover}"
                                            class="rounded-card"
                                            color="#385F73"
                                            :to="item.link"
                                            align="center"
                                    >
                                        <v-card-title class="text-h6 white--text">
                                            <v-row
                                                    class="fill-height flex-column"
                                                    justify="space-between"
                                            >
                                                <div class="mt-4">
                                       <span class="material-icons material-icons-main">
                                            {{ item.icon }}
                                        </span>
                                                </div>
                                                <p class="mt-2 subheading text-center">
                                                    {{ item.title }}
                                                </p>
                                            </v-row>
                                        </v-card-title>
                                    </v-card>
                                </v-hover>
                            </v-col>
                        </template>
                    </v-row>
                </v-container>
            </v-col>

        </v-row>
        <v-row>
            <v-col>
                <v-container>
                    <main-carousels3></main-carousels3>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="bg-gray">
            <v-col>
                <v-container>
                    <main-carousels2></main-carousels2>
                </v-container>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container>
                    <main-carousels4></main-carousels4>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="bg-gray">
            <v-col>
                <v-container>
                    <main-carousels5></main-carousels5>
                </v-container>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container>
                    <main-notice></main-notice>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import MainCarousels from '../components/MainCarousels'
    import MainCarousels2 from '../components/MainCarousels2'
    import MainCarousels3 from '../components/MainCarousels3'
    import MainCarousels4 from '../components/MainCarousels4'
    import MainCarousels5 from '../components/MainCarousels5'
    import MainNotice from '../components/MainNotice'

    export default {
        name: 'Home',

        components: {
            MainCarousels5,
            MainCarousels4,
            MainCarousels3,
            MainCarousels2,
            MainCarousels,
            MainNotice
        },
        data: () => ({
            items: [
                {
                    title: '배치도',
                    icon: 'map',
                    link: '/place/campMap',
                },
                {
                    title: '공지사항',
                    icon: 'campaign',
                    link: '/community/notice',
                },
                {
                    title: '이용안내',
                    icon: 'payment',
                    link: '/userGuide/main',
                },
                {
                    title: '주변관광',
                    icon: 'image',
                    link: '/userGuide/near',
                },
                {
                    title: '오시는길',
                    icon: 'moving',
                    link: '/about/roadMap',
                },
                {
                    title: '예약하기',
                    icon: 'book_online',
                    link: '/reservation/search',
                },
            ],
            transparent: 'rgba(255, 255, 255, 0)',
            noticeList: [
                {
                    noticeId: "1",
                    noticeGubun: "true",
                    noticeTitle: "공지1",
                    /*noticeContent: '<img src="/file/73da934b-197c-46f3-90a3-53fa8f58e5c4.png" alt="alt text" />',*/
                    noticeContent: "/file/73da934b-197c-46f3-90a3-53fa8f58e5c4.png",
                    noticeOrder: "1",
                    useYn: "Y",
                    regDate: "",
                    lstModPrs: "",
                    lstModDate: ""
                },
                /*{
                    noticeId: "2",
                    noticeGubun: "false",
                    noticeTitle: "제목입니다2",
                    noticeContent: "내용입니다.2",
                    noticeOrder: "1",
                    useYn: "Y",
                    regDate: "",
                    lstModPrs: "",
                    lstModDate: ""
                },*/
            ],
        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            //this.$store.commit("room/SAVE", this.$store.state.room.roomData9);

            this.setPop();

        },
        methods: {
            setPop() {

            }

        }

    }
</script>

<style scoped>
    .v-card {
        transition: opacity .4s ease-in-out;
    }

    .v-card:not(.on-hover) {
        opacity: 0.6;
    }

    .show-btns {
        color: rgba(255, 255, 255, 1) !important;
    }
</style>

