import Vue from 'vue'
import axios from 'axios'
import api from "@/api/index"
//import api from "@/api/index"

const imgInfo = {
    namespaced: true,
    state: {


    },
    mutations: {
        LOAD: (state, info) => {

        }
        , CLEAR({state}) {
        }


    },

    actions: {
        getImgList({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.imgInfo.getImgList(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {

                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getImgInfo({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.imgInfo.getImgInfo(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {

                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateImg({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.imgInfo.updateImg(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }

                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updatesImg({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.imgInfo.updatesImg(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        deleteImg({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.imgInfo.deleteImg(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            //commit("LOGIN", resp);
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },


    }
    , getters: {
        // isLogin : state => state._id == "" ? false : true
    }
}

export default imgInfo
