import axios from '@/api/api.core.js'
import store from '@/store'

//방 목록 호출 (예약 가능 방)
function getRoomKate(formdata) {
    return axios({
        url: "/api/room/resv/getAbleList.do",
        method: 'get',
        params: formdata,

    })
}

function resvUpdate(formdata) {
    return axios({
        url: "/api/room/resv/update.do",
        method: 'post',
        data: formdata,

    })
}

function resvDelete(formdata) {
    return axios({
        url: "/api/room/resv/delete.do",
        method: 'post',
        data: formdata,

    })
}

// 예약목록 호출
function resvConfirmList(formdata) {
    return axios({
        url: "/api/room/resv/getList.do",
        method: 'get',
        params: formdata,

    })
}

// 예약 취소
function cancelResv(formdata) {
    return axios({
        url: "/api/room/resv/deleteData.do",
        method: 'get',
        params: formdata,

    })
}

// 부대시설정보 가져오기
function getCampItem(formdata) {
    return axios({
        url: "/api/room/getCampItemList.do",
        method: 'get',
        params: formdata,

    })
}

// 계좌이체 최종 확인 업데이트
function orderUpdate(formdata) {
    return axios({
        url: "/api/room/resv/order/update.do",
        method: 'post',
        data: formdata,

    })
}


export default {

    getRoomKate
    , resvUpdate
    , resvDelete
    , resvConfirmList
    , cancelResv
    , getCampItem
    , orderUpdate

}


