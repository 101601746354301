import axios from 'axios'
// import { Message } from 'element-ui'
import store from '@/store'

// console.log("model core =========================== : ");
// console.log("BASE_API   : "+process.env.BASE_API);
// console.log("ENV_CONFIG : "+process.env.ENV_CONFIG);
// console.log("BASE_API   : "+process.env.BASE_API);
// console.log("BASE_ADMIN_API : "+process.env.BASE_ADMIN_API);
// console.log("BASE_URL   : "+process.env.BASE_URL);
// console.log("=========================== : ");


//const BASE_URL = "http://211.104.172.28:8080/"; // lwm2m 서버
//const BASE_URL = "http://211.238.111.60:5000/"; // 내컴퓨터 외부 ip
//const BASE_URL = "http://127.0.0.1:8082/";    //내컴퓨터 내부 ip
//const BASE_URL = "http://211.238.111.60:18082";  // 사무실 개발 서버
//const BASE_URL = "http://192.168.0.33:8080";  //
//const BASE_URL = "http://dlms2.anystar4u.com/"; // 서버 주소
//const BASE_URL = "http://192.168.10.213:8082/"; // ITSM 노트북 서버
//const BASE_URL = "http://192.168.0.125:8082/"; // 노트북 서버

//const BASE_URL = "http://192.168.0.100:8082/"; // 내컴 서버

let BASE_URL = void 0;

let host = void 0;
let port = void 0;
switch (document.location.hostname) {
  case "localhost":
  case "127.0.0.1": {
    host = "192.168.0.100";
    port = "8082";
    break;
  }
  case "211.238.111.60": {
    host = "211.238.111.60";
    port = "58082";
    break;
  }
  case "192.168.0.100":{
    host = "192.168.0.100";
    port = "8082";
    break;
  }
  case "valley.anystar4u.com": {
      host = "valley.anystar4u.com";
      port = null;
      break;
  }
  default: {
    host = document.location.hostname;
    port = null;
    break;
  }
}

let protocol = document.location.protocol;

BASE_URL = protocol + "//" + host + (port == null? "" : (":" + port));

/*if (document.location.protocol == 'http:') {
    //document.location.href = document.location.href.replace('http:', 'https:');
    BASE_URL = "http://" + host + (port == null? "" : (":" + port));

}else {
    BASE_URL = "https://" + host + (port == null? "" : (":" + port));

}*/
//BASE_URL = "http://" + host + (port == null? "" : (":" + port));


//console.log("BASE_URL : " + BASE_URL);

const service = axios.create({
  baseURL: BASE_URL,
  headers: {'Content-Type': 'application/json'},
  timeout: 60000
});

//Access-Control-Allow-Headers : content-type
//multipart/form-data
//

service.removeEmpty = function (a_item) {
  var item = a_item;
  $.each(item, function (key, val) {
    if (val == "" || val == "0") {
      delete item[key];
    }
  });
  return item;
}

service.interceptors.request.use(config => {

  // page 정보가 있으면 페이지 정보를 설정한다.
  /* if( config.data && config.data["page"] !== undefined ){
       var pagger = make_pagger(config.data.page) ;
       config.data["offset"]  = pagger.offset;
       config.data["pageCount"] = pagger.limit;
       delete config.data["page"];
   }
   if( config.data && config.data.ORDERBY == false ){
       delete config.data.ORDERBY;
   }
   if( config.data && config.data.WHERE == false ){
       delete config.data.WHERE;
   }*/

  // if (store.getters.token) {
  //   config.headers['X-Token'] = getToken()
  // }

  return config
}, error => {
  console.log(error) // for debug
  Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
  // response => response.data,
  response => {
    // console.log("res");
    return response.data;
  },
  (error) => {
    // // console.log('err' + error) // for debug
    // let message = "";
    // if( error.response && error.response.data && error.response.data.message){
    //     message = error.response.data.message;
    // }else{
    //     message = error.message;
    // }
    // // console.log("model.core error",error);
    // Message({
    //   message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    try {
      Vue.$alert(error.response.status + "-" + error.request.responseURL.substring(27));
      console.log(error);
    } catch (err) {
      Vue.$alert("서버에서 알수 없는 응답 입니다.");
    }
    return Promise.reject(error)
  })


export default service
