import Vue from 'vue'
import axios from 'axios'
import api from "@/api/index"
//import api from "@/api/index"

const board = {
    namespaced: true,
    state: {
        boardId: "",
        boardGubun: "",
        boardInfo: {
            boardId: "1",
            boardGubun: "",
            boardTitle: "",
            boardContent: "",
            lstModPrs: "",
            lstModDate: "",
        },
        boardList: []


    },
    mutations: {
        LOAD: (state, info) => {

        }
        , CLEAR({state}) {
        }
        , SAVE_BOARD_GUBUN: (state, info) => {
            try {
                // 1. default setting

                Vue.set(state, 'boardGubun', info);

            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , SAVE_BOARD_ID: (state, info) => {
            try {
                // 1. default setting

                Vue.set(state, 'boardId', info);

            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , CLEAR_BOARD_ID(state) {
            Vue.set(state, 'boardId', "");
        }
        , SAVE_BOARD_LIST: (state, info) => {
            try {
                // 1. default setting

                Vue.set(state, 'boardList', info.message);

            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }
        , SAVE_BOARD_INFO: (state, info) => {
            try {
                // 1. default setting
                Vue.set(state, 'boardInfo', info.message);

            } catch (err) {
                console.err("${err} :  ${data}");
            }
            return state;
        }

    },

    actions: {
        getBoardList({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.board.getBoardList(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {

                            commit("SAVE_BOARD_LIST", resp);
                            resolve(resp);
                        } else {

                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        getBoardInfo({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.board.getBoardInfo(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            commit("SAVE_BOARD_INFO", resp);
                            resolve(resp);
                        } else {

                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateBoard({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.board.updateBoard(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            commit("SAVE_BOARD_INFO", resp);
                            commit("SAVE_BOARD_ID", resp.message.boardId);
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        updateHitCnt({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.board.updateHitCnt(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            commit("SAVE_BOARD_INFO", resp);
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        deleteBoard({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.board.deleteBoard(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            //commit("LOGIN", resp);
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        insertComt({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.board.insertComt(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            commit("SAVE_BOARD_INFO", resp);
                            commit("SAVE_BOARD_ID", resp.message.boardId);
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        deleteComt({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.board.deleteComt(fdata)
                    .then(function (resp) {

                        if (resp.result == 'succeed') {
                            //commit("LOGIN", resp);
                            resolve(resp);
                        } else {
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        deleteAdmin({commit, dispatch, getters}, fdata) {

            return new Promise((resolve, reject) => {
                api.board.deleteAdmin(fdata)
                    .then(function (resp) {
                        if (resp.result == 'succeed') {
                            //commit("LOGIN", resp);
                            resolve(resp);
                        } else {
                            alert(resp.message);
                            reject(error);
                        }


                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
    }
    , getters: {
        // isLogin : state => state._id == "" ? false : true
    }
}

export default board
